import Dom = require("Everlaw/Dom");
import TextBox = require("Everlaw/UI/TextBox");
import UI = require("Everlaw/UI");
import UI_Validated = require("Everlaw/UI/Validated");
import Widget = require("Everlaw/UI/Widget");
import { TimezoneN } from "Everlaw/DateUtil";

abstract class ConstrainedBox<V> extends Widget implements UI.WidgetWithTextBox {
    rwInfo: ConstrainedBox.RangeWidgetInfo; // Present iff this is part of a RangeWidget.
    onSubmit(val: V, me: this) {}
    onChange(val: V, me?: this) {}
    onBlur(me?: this) {}
    onInput() {}
    form: UI_Validated.ValidatedTextBox; // Type is boxType()
    protected abstract boxType(): any; // must be overridden!! Type should be a dijit.form.*Box
    protected getBox(params: ConstrainedBox.Params) {
        return new (this.boxType())(
            Object.assign(
                {
                    name: params.placeholder,
                    placeholderMessage: params.placeholder,
                    invalidMessage: params.invalidMessage,
                    required: !!params.required,
                    onChange: params.onChange,
                    onSubmit: params.onSubmit,
                    onBlur: params.onBlur,
                    width: params.width,
                },
                params.boxFormat,
                params.textBoxParams,
            ),
        );
    }
    constructor(params: ConstrainedBox.Params) {
        super();
        this.form = this.getBox(params);
        this.node = Dom.node(this.form);
        if (params.textAlign) {
            Dom.style(this.node, "textAlign", params.textAlign);
        }
        if (params.width) {
            Dom.style(this.node, "width", params.width);
        }
        UI.onSubmit(
            Dom.node(this.form),
            () => {
                this.onSubmit(this.getValue(), this);
            },
            this,
        );
        this.connect(Dom.node(this.form), "change", () => {
            this.onChange(this.getValue(), this);
        });
        this.connect(Dom.node(this.form), "blur", () => {
            this.onBlur(this);
        });
        this.connect(Dom.node(this.form), "input", () => {
            this.onInput();
        });
    }
    override destroy() {
        super.destroy();
        this.form.destroy();
    }
    require(isRequired: boolean) {
        this.form.require(isRequired);
    }
    setDisabled(val: boolean) {
        this.form.setDisabled(val);
    }
    isDisabled() {
        return this.form.isDisabled();
    }
    getValue(): V {
        return this.form.getValue();
    }
    setValue(val: any, silent?: boolean) {
        this.form.setValue(val, silent);
    }
    setMin(val: any) {
        this.form.setMin(val);
    }
    resetMin() {
        this.form.resetMin();
    }
    setMax(val: any) {
        this.form.setMax(val);
    }
    resetMax() {
        this.form.resetMax();
    }
    override focus() {
        this.form.focus();
    }
    setWidth(width: string) {
        this.form.setWidth(width);
    }
    placeErrorMessage(node: HTMLElement, position?: string) {
        Dom.place(this.form.errorDiv, node, position);
    }
    getForm() {
        return this.form;
    }
    linkErrorMessage(form: UI_Validated.ValidatedTextBox) {
        this.form.linkErrorMessage(form);
    }
    setTextBoxAriaLabel(ariaLabel: string): void {
        this.form.setTextBoxAriaLabel(ariaLabel);
    }
    setTextBoxLabelContent(labelContent: Dom.Content): void {
        this.form.setTextBoxLabelContent(labelContent);
    }
    setTextBoxLabelPosition(position: TextBox.LabelPosition): void {
        this.form.setTextBoxLabelPosition(position);
    }
}

module ConstrainedBox {
    export interface Params {
        placeholder?: string;
        required?: boolean;
        width?: string;
        textAlign?: string;
        boxFormat?: any; // up to the subclass to determine format parameters
        // allows subclasses to have a way to specify what box to created if multiple types of boxes
        // also enables use of subclass's enums
        onChange?: (val: any, me?: ConstrainedBox<any>) => void;
        onSubmit?: (val: any, me?: ConstrainedBox<any>) => void;
        invalidMessage?: string;
        onBlur?: () => void;
        format?: any; // up to the subclass to determine format parameters
        textBoxParams?: UI.WidgetWithTextBoxParams;
        validateIfTextUnchanged?: boolean;
        timezone?: TimezoneN;
    }

    /**
     * For ConstrainedBoxes that are part of a RangeWidget (or similar), this object contains some
     * information about the relationship between the two ConstrainedBoxes.
     */
    export interface RangeWidgetInfo {
        other: ConstrainedBox<any>;
        isBegin: boolean; // Is this ConstrainedBox the "begin" widget or the "end" widget?
    }
}

export = ConstrainedBox;
